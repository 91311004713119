<template>
  <div class="course">
    <div class="course__header">
      <router-link
        :to="{ name: 'course_category', params: { link: course.category.link } }"
        class="course__category"
        v-if="course.category"
      >
        {{ course.category.title }}
      </router-link>
    </div>
    <div class="course__img-container">
      <router-link :to="{ name: 'account_education_list', params: { id: course.id } }">
        <img
          class="course__img"
          :alt="course.head_img | image_alt"
          :src="course.head_img | image($store.state._env.MEDIA_ENDPOINT)"
        />
      </router-link>
      <router-link
        :to="{ name: 'course_category', params: { link: course.category.link } }"
        class="course__category"
        v-if="course.category"
      >
        {{ course.category.title }}
      </router-link>
    </div>
    <router-link :to="{ name: 'account_education_list', params: { id: course.id } }" class="course__info">
      <h2 class="course__title">{{ course.title }}</h2>
      <span class="course__description">{{ course.short_text }}</span>
    </router-link>
    <div class="course__progress">
      <div class="course__progress-title">
        <span>{{ passedLessons }}/{{ lessons.length }} уроков</span>
        <span>пройдено {{ progress }}%</span>
      </div>
      <div class="course__progress-line">
        <span ref="percent" :style="`width: ${progress}%`"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseProgressComponent",
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    progress() {
      return this.passedLessons ? Math.round(100 / (this.lessons.length / this.passedLessons)) : 0;
    },
    passedLessons() {
      return this.lessons.filter((l) => l.passed).length;
    },
    lessons() {
      return this.course.modules
        .map((module) => module.lessons)
        .flat()
        .map((lesson) => {
          return {
            id: lesson.id,
            passed: !!lesson.auth_passed_lesson,
          };
        });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/course.styl"
</style>
